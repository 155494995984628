import React, { Fragment, useState, useEffect } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"

import Layout from "../../components/layout"
import "../../styles/customerPage.css"
import Axios from "axios"
import baseUrl from "../../components/baseUrl"
import CustomerCard from "../../components/customerCard"
import Confirm from "../../components/confirm"

function Customers() {
  //State variables to record form responses
  const [name, setName] = useState("")
  const [age, setAge] = useState(0)
  const [gender, setGender] = useState("Female")
  const [contact1, setContact1] = useState("")
  const [contact2, setContact2] = useState("")

  const [course, setCourse] = useState("Yoga Circle")
  const [time, setTime] = useState("")
  const [remark, setRemark] = useState([])
  const [added, setAdded] = useState(false)

  const courses = [
    {
      id: 1,
      name: "Yoga Circle",
    },
    {
      id: 2,
      name: "One to One",
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  const [showModal, setShowModal] = useState(false)
  const [showConfirmModal, setConfirmShowModal] = useState(false)
  const [customers, setCustomers] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedCourse, setSelected] = useState(courses[0])
  const [errors, setErrors] = useState({
    name: "",
    age: "",
    contact1: "",
    contact2: "",
    time: "",
  })

  useEffect(() => {
    let endpoint
    selectedCourse["name"] === "Yoga Circle"
      ? (endpoint = "participants?category=group")
      : (endpoint = "participants?category=yoga")
    Axios.get(`${baseUrl}/${endpoint}`).then(jsonRes => {
      setCustomers(jsonRes.data)
    })
  }, [selectedCourse, added])

  const validate = () => {
    let newObj = { ...errors }
    if (name.length <= 0) {
      newObj.name = "Name field cannot be empty"
      setErrors(newObj)
      return false
    } else {
      newObj.name = ""
      setErrors(newObj)
    }
    if (time.length <= 0) {
      newObj.time = "Cannot be empty"
      setErrors(newObj)
      return false
    } else {
      newObj.time = ""
      setErrors(newObj)
    }

    if (age <= 0 || age >= 120) {
      newObj.age = "Invalid age"
      setErrors(newObj)
      return false
    } else {
      newObj.age = ""
      setErrors(newObj)
    }

    if (
      contact1.length < 10 ||
      contact1.length === 11 ||
      contact1.length > 12
    ) {
      newObj.contact1 = "Invalid phone number"

      setErrors(newObj)
      return false
    } else {
      newObj.contact1 = ""
      setErrors(newObj)
    }
    if (
      contact2.length < 10 ||
      contact2.length === 11 ||
      contact2.length > 12
    ) {
      newObj.contact2 = "Invalid phone number"

      setErrors(newObj)
      return false
    } else {
      newObj.contact2 = ""
      setErrors(newObj)
    }
    return true
  }
  //function to add customer
  const handleAdd = () => {
    let bool_val = validate()
    let insName = ""
    if (bool_val === true) {
      setShowModal(false)
      let newArray = {
        name: name,
        contact1: contact1,
        contact2: contact2,
        gender: gender,
        course: course,
        time: time,
        remarks: [],
        age: age,
      }
      if (typeof window !== "undefined") {
        insName = window.localStorage.getItem("user")
      }

      //Converting date object to proper string
      let dateObj = new Date()
      var month = dateObj.getUTCMonth() + 1 //months from 1-12
      var day = dateObj.getUTCDate()
      var year = dateObj.getUTCFullYear()
      let newDate = day + "/" + month + "/" + year

      //creating new remark temporary object
      let newRemark = {
        date: newDate,
        instructor: insName,
        remark: remark,
      }
      if (remark.length !== 0) {
        newArray.remarks.push(newRemark)
      }

      console.log(newArray)
      //Posting new customer

      Axios.post(`${baseUrl}/addCustomer`, newArray)
        .then(() => alert(newArray))
        .then(() => setAdded(!added))
        .catch(err => alert(err))
      setShowModal(false)
    }
    setConfirmShowModal(false)
  }

  return (
    <Layout route="/customers">
      <div className="customerPage">
        <div className="grid grid-rows-1 mainRow">
          <div className="grid grid-cols-12">
            <div className="col-span-8 col-start-1">
              {" "}
              <h2 className="p-6">CUSTOMERS</h2>
            </div>
            <div className="col-span-3 col-start-9 md:col-start-11 p-6">
              {" "}
              <button
                className="modalButton text-white active:bg-green-600  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                +
              </button>
            </div>
          </div>
        </div>
        {/* Choosing a category of customers */}
        <div className="grid grid-rows-1 md:p-6 md:pr-0">
          <div className="grid grid-cols-12">
            <div className="col-span-10 col-start-2 md:col-span-4 md:col-start-1">
              <Listbox value={selectedCourse} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      Choose a category
                    </Listbox.Label>
                    <div className="mt-1 relative">
                      <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {selectedCourse.name}
                          </span>
                        </span>
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {courses.map(course => (
                            <Listbox.Option
                              key={course.id}
                              className={({ active }) =>
                                classNames(
                                  selectedCourse["name"] === course["name"]
                                    ? "text-white activeOption"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value={course}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selectedCourse["name"] ===
                                          course["name"]
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {course.name}
                                    </span>
                                  </div>

                                  {selectedCourse["name"] === course["name"] ? (
                                    <span
                                      className={classNames(
                                        selectedCourse["name"] ===
                                          course["name"]
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            {/* Search bar */}
            <div className="col-span-10 col-start-2 md:col-span-5 md:col-start-7 mt-9 md:mt-0">
              <div class="searchContainer h-20 flex justify-center items-center px-4 py-4 sm:px-6 lg:px-8">
                <div class="relative">
                  {" "}
                  <input
                    type="text"
                    class="h-14 w-full  pr-8 pl-5 rounded z-0 shadow  focus:outline-blue-200"
                    placeholder="Search by name"
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <div class="absolute top-4 right-3">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="hover:text-gray-900 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Page body  */}
        <div className="customerList mt-8 grid grid-cols-12">
          {customers.length === 0 ? (
            <div className="col-start-1 col-span-6 text-right">No records</div>
          ) : (
            customers
              .filter(val => {
                if (searchTerm === "") {
                  return val
                } else if (
                  val.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val
                }
              })
              .map(customer => {
                return (
                  <div className="col-span-12 col-start-1 md:col-span-4 ">
                    <CustomerCard
                      id={customer._id}
                      name={customer.name}
                      time={customer.time}
                      contact1={customer.contact1}
                      contact2={customer.contact2}
                      gender={customer.gender}
                    />
                  </div>
                )
              })
          )}
        </div>

        {/* Add customer modal  */}
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto  max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-3xl font-semibold">Add Customer</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <form class="w-full max-w-lg">
                      <div class="flex flex-wrap -mx-3 mb-0 md:mb-6">
                        <div class="w-full px-3 mb-1 md:mb-0">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                            Name
                          </label>
                          <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            required
                            onChange={e => {
                              setName(e.target.value)
                            }}
                          />
                          <p className="text-xs error">{errors.name}</p>
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                            Contact 1
                          </label>
                          <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="contact1"
                            type="text"
                            onChange={e => {
                              setContact1(e.target.value)
                            }}
                          />
                          <p className="text-xs error">{errors.contact1}</p>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                            Contact 2
                          </label>
                          <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="contact2"
                            type="text"
                            onChange={e => {
                              setContact2(e.target.value)
                            }}
                          />
                          <p className="text-xs error">{errors.contact2}</p>
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 mb-2 md:mb-6">
                        <div class="w-1/2 md:w-1/2 px-3 mb-1 md:mb-0">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                            Gender
                          </label>
                          <div class="relative">
                            <select
                              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="genderDropdown"
                              onChange={e => {
                                setGender(e.target.value)
                              }}
                            >
                              <option>Female</option>
                              <option>Male</option>
                              <option>Others</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="w-1/2 md:w-1/2 px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                            Age
                          </label>
                          <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="age"
                            type="number"
                            onChange={e => {
                              setAge(e.target.value)
                            }}
                          />
                          <p className="text-xs error">{errors.age}</p>
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-1/2 md:w-1/2 px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                            Course
                          </label>
                          <div class="relative">
                            <select
                              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="courseDropdown"
                              onChange={e => {
                                setCourse(e.target.value)
                              }}
                            >
                              <option>Yoga Circle</option>
                              <option>One to One</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="w-1/2 md:w-1/2 px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                            Time slot
                          </label>
                          <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="slot"
                            type="text"
                            onChange={e => {
                              setTime(e.target.value)
                            }}
                          />
                          <p className="text-xs error">{errors.time}</p>
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3  mb-6">
                        <div class="w-full  px-3 mb-1 md:mb-0">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-0 md:mb-6">
                            Remarks
                          </label>
                          <textarea
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-zip"
                            onChange={e => {
                              setRemark(e.target.value)
                            }}
                            placeholder="NIL"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-3 md:p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-semibold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-semibold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setConfirmShowModal(true)
                      }}
                    >
                      ADD CUSTOMER
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {showConfirmModal ? (
          <Confirm
            title="Add customer?"
            task={handleAdd}
            setShowModal={setConfirmShowModal}
          />
        ) : null}
      </div>
    </Layout>
  )
}

export default Customers
