import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { TrashIcon } from "@heroicons/react/solid"
// import Confirm from "./confirm"
import Axios from "axios"
import baseUrl from "./baseUrl"

function CustomerCard(props) {
  const [showConfirmDeleteModal, setDeleteShowModal] = useState(false)
  //function to delete the customer
  const handleDelete = id => {
    
    Axios.patch(`${baseUrl}/deleteCustomer`, { data: id })
      
      .catch(err => console.log(err))
    
  }
  return (
    <>
      <Link to={`/customers/participant/${props.id}`} key={props.id}>
        {" "}
        <div className="p-10">
          <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <div className="px-6 py-4">
              <div className="flex ">
                {" "}
                <div className="font-semibold text-xl mb-2">
                  {props.name}{" "}
                </div>{" "}
                <span className="ml-auto z-2">
                  <button
                    onClick={e => {
                      e.preventDefault()
                      setDeleteShowModal(true)
                    }}
                  >
                    <TrashIcon
                      className="h-5 w-5 text-red-600"
                      aria-hidden="true"
                    />
                  </button>
                </span>
              </div>
              <p className="text-gray-700 text-base">{props.time}</p>
            </div>
            <div className="px-6 pt-4 pb-2 ">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                #{props.gender}
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                #{props.contact1}
              </span>
            </div>
          </div>
        </div>
      </Link>

      {showConfirmDeleteModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-5 w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h6 className="text-3xl font-semibold">
                    Delete customer {props.name}
                  </h6>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setDeleteShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-semibold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setDeleteShowModal(false)}
                  >
                    NO
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-semibold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      handleDelete(props.id)
                      setDeleteShowModal(false)
                    }}
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}

export default CustomerCard
